<template>
  <v-row>
    <v-col :cols="showFilter && !mobile ? 9 : 12">
      <template v-if="(isCategoryMode && selectedCategory) || mode === 'SEARCH'">
        <list-header
          :title="
            isCategoryMode && selectedCategory
              ? selectedCategory.name
              : $t('searchResults', [$route.query.keyword]).toString()
          "
        >
          <template v-if="$vuetify.display.lgAndUp" #viewToggle>
            <view-toggle
              :views="getProductViews(portalSettings.showSuppliers)"
              :current-view="ownUser.setting.preferedProductSearchView"
              :toggle-event="toggleViewEvent"
              @changed="changeView"
            />
          </template>
          <template v-if="$vuetify.display.lgAndUp" #indicator>
            <realtime-prices-indicator
              class="ml-2"
              :loading="productsLoading > 0 || realtimeLoading.length > 0"
            />
          </template>
          <template v-if="isCategoryMode" #search>
            <search-bar :value="keyword" @search="setKeyword" @clear="resetSearch" />
          </template>
          <template #filter>
            <categories-button
              v-if="isCategoryMode && mobile"
              @toggle="showCategories = !showCategories"
            />
            <filter-button :active-filters="getActiveFilterLength" @toggle="toggleFilter" />
          </template>
          <template #cols>
            <category-breadcrumbs
              v-if="isCategoryMode"
              class="align-self-center"
              :open-categories="openCategories"
            />
            <v-breadcrumbs class="ml-auto align-self-center flex-wrap flex-row-reverse pa-0">
              <v-breadcrumbs-item
                v-for="filterNode in getActiveFilters"
                :key="filterNode.name"
                style="margin-top: 1px; margin-bottom: 1px"
              >
                <span class="mr-1">{{ filterNode.name }}</span>
                <v-chip
                  v-for="value in filterNode.values"
                  :key="value.name"
                  :closable="filterNode !== getActiveFilters[0] || !isCategoryMode"
                  @click:close="removeFilter(filterNode.name, value)"
                  >{{ value.name }}</v-chip
                >
              </v-breadcrumbs-item>
              <v-breadcrumbs-item
                v-if="getPriceRangeFilter?.start || getPriceRangeFilter?.end"
                style="margin-top: 1px; margin-bottom: 1px"
              >
                <span class="mr-1">{{ $t('priceRange.priceRange') }}</span>
                <v-chip closable @click:close="removePriceRangeFilter()">
                  {{ getPriceRangeFilterString(getPriceRangeFilter) }}
                </v-chip>
              </v-breadcrumbs-item>
              <v-breadcrumbs-item
                v-if="getStockCountFilter"
                style="margin-top: 1px; margin-bottom: 1px"
              >
                <span class="mr-1">{{ $t('quantityFrom') }}</span>
                <v-chip closable @click:close="removeStockCountFilter()">
                  {{ getStockCountFilter }}
                </v-chip>
              </v-breadcrumbs-item>
              <v-breadcrumbs-item v-if="getActiveFilterLength === 0">
                <v-chip color="transparent">
                  <sf-text>
                    {{ $t('filter.noFiltersSelected') }}
                  </sf-text>
                </v-chip>
              </v-breadcrumbs-item>
            </v-breadcrumbs>
          </template>
        </list-header>
        <v-row dense>
          <v-col cols="12">
            <realtime-prices-indicator
              v-if="$vuetify.display.mdAndDown"
              class="ml-2"
              :loading="productsLoading > 0 || realtimeLoading.length > 0"
            />
          </v-col>
        </v-row>
      </template>

      <template v-else>
        <v-skeleton-loader class="mb-1" type="heading" />
        <v-divider class="my-3" />
        <v-skeleton-loader class="mb-2" type="text" />
      </template>

      <products-cards
        v-if="
          mdAndDown || ownUser.setting.preferedProductSearchView === PreferedProductSearchView.CARDS
        "
        :products="items"
        :loading="categoriesLoading || productsLoading > 0"
        :cards-lg="showFilter ? 4 : 3"
        :cards-xl="showFilter ? 3 : 2"
        :add-to-cart-event="addToCartEvent"
      />

      <products-list
        v-if="
          $vuetify.display.lgAndUp &&
          ownUser.setting.preferedProductSearchView === PreferedProductSearchView.LIST
        "
        ref="products-list"
        :products="items"
        :loading="categoriesLoading || productsLoading > 0"
        :portal-settings="portalSettings"
        @select="showFilter = false"
      />
    </v-col>

    <mobile-side-menu
      v-if="isCategoryMode && mobile"
      :show="showCategories"
      @close="showCategories = false"
    >
      <product-categories
        show-close-button
        @update="selectCategory"
        @close="showCategories = false"
      />
    </mobile-side-menu>
    <mobile-side-menu v-if="mobile" :show="showFilter" @close="showFilter = false">
      <product-filter
        :filter-mode="mode"
        :filter-tracking-function="trackFilter"
        :sort-tracking-function="trackSort"
      />
    </mobile-side-menu>

    <v-col v-if="!mobile" v-show="showFilter" cols="3">
      <product-filter
        :filter-mode="mode"
        :filter-tracking-function="trackFilter"
        :sort-tracking-function="trackSort"
        @close="showFilter = false"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import CategoriesButton from '@/components/CategoriesButton.vue'
import FilterButton from '@/components/filter/FilterButton.vue'
import { getPriceRangeFilterString } from '@/components/filter/helpers'
import ListHeader from '@/components/list/ListHeader.vue'
import MobileSideMenu from '@/components/MobileSideMenu.vue'
import RealtimePricesIndicator from '@/components/RealtimePricesIndicator.vue'
import SearchBar from '@/components/SearchBar.vue'
import SfText from '@/components/text/SfText.vue'
import ViewToggle from '@/components/ViewToggle.vue'
import { FilterValue, PreferedProductSearchView } from '@/generatedTypes'
import { useTitle } from '@/helpers'
import CategoryBreadcrumbs from '@/modules/categories/components/CategoryBreadcrumbs.vue'
import ProductCategories from '@/modules/categories/components/ProductCategories.vue'
import useCategories from '@/modules/categories/useCategories'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import ProductFilter from '@/modules/product/components/ProductFilter.vue'
import ProductsCards from '@/modules/product/components/ProductsCards.vue'
import ProductsList from '@/modules/product/components/ProductsList.vue'
import { getProductViews, useProductRouterQuery } from '@/modules/product/helpers'
import { ProductPageMode, ProductSearchOptions, ProductSort } from '@/modules/product/types'
import useProductFilter from '@/modules/product/useProductFilter'
import useProductSearch from '@/modules/product/useProductSearch'
import useRealtimePrices from '@/modules/product/useRealtimePrices'
import CategoryEvent from '@/modules/tracking/events/category'
import FilterEvent from '@/modules/tracking/events/filter'
import ProductSearchEvent from '@/modules/tracking/events/search'
import { trackEvent } from '@/modules/tracking/useTracking'
import useOwnUser from '@/modules/user/useOwnUser'
import router from '@/router'
import { storeToRefs } from 'pinia'
import {
  ComponentPublicInstance,
  PropType,
  computed,
  defineComponent,
  onBeforeMount,
  onUnmounted,
  ref,
  watch
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useDisplay } from 'vuetify'

export default defineComponent({
  name: 'ProductsPage',
  components: {
    CategoriesButton,
    CategoryBreadcrumbs,
    FilterButton,
    ListHeader,
    MobileSideMenu,
    ProductCategories,
    ProductFilter,
    ProductsCards,
    ProductsList,
    SearchBar,
    SfText,
    ViewToggle,
    RealtimePricesIndicator
  },
  props: {
    mode: {
      type: String as PropType<ProductPageMode>,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n()
    const currentRoute = useRoute()
    const pageTitle = computed(() => {
      let title = t('products')
      switch (
        (
          props as Readonly<{
            mode: ProductPageMode
          }>
        ).mode
      ) {
        case 'SEARCH':
          title = `${t('searchResults', [currentRoute.query.keyword])}`
          break
        case 'CATEGORY':
          title = `${t('products')}`
          break
      }
      return title
    })
    useTitle(pageTitle)
    const { keyword, filters, sort } = useProductRouterQuery(currentRoute)
    const showFilter = ref(false)
    const showCategories = ref(false)
    const { portalSettings } = storeToRefs(usePortalSettings())
    const { ownUser } = storeToRefs(useOwnUser())
    const { loading: realtimeLoading } = storeToRefs(useRealtimePrices())
    const { items, loading: productsLoading } = storeToRefs(useProductSearch())
    const { getActiveFilters, getActiveFilterLength, getPriceRangeFilter, getStockCountFilter } =
      storeToRefs(useProductFilter())
    const {
      selectedCategory,
      openCategories,
      loading: categoriesLoading
    } = storeToRefs(useCategories())
    const productsListRef = ref<ComponentPublicInstance<typeof ProductsList>>()
    const { mobile, mdAndDown } = useDisplay()
    const isCategoryMode = computed(() => props.mode === 'CATEGORY')

    const searchProducts = () => {
      if (props.mode === 'CATEGORY') {
        useCategories().getProductsForCategory(
          currentRoute.params.id as string,
          keyword.value,
          filters.value
        )
      } else {
        if (keyword.value) {
          const searchOptions: ProductSearchOptions = {
            keyword: keyword.value,
            filters: filters.value,
            sort: sort.value
          }
          useProductSearch().searchProducts(searchOptions)
          useProductFilter().getFilters(searchOptions)
        }
      }
    }

    onBeforeMount(() => {
      if (sort.value) {
        useProductFilter().selection.sort = sort.value
      } else {
        useProductFilter().selection.sort =
          props.mode === 'CATEGORY' ? ProductSort.POPULARITY : ProductSort.RELEVANCE
      }

      if (props.mode === 'CATEGORY' && useCategories().categories.length === 0) {
        useCategories().initCategories(
          currentRoute.params.id as string,
          keyword.value,
          filters.value
        )
      } else {
        searchProducts()
      }
    })

    onUnmounted(() => {
      useCategories().selectedCategory = undefined
    })

    watch(currentRoute, (from, to) => {
      if (to.query.view == undefined || to.query.view == from.query.view) {
        searchProducts()
      }
    })

    watch(
      () => props.mode,
      () => {
        if (props.mode == 'SEARCH') {
          useCategories().selectedCategory = undefined
        }
      }
    )

    const changeView = (view: PreferedProductSearchView) => {
      useOwnUser().ownUser.setting.preferedProductSearchView = view
      useOwnUser().updateUserSettings({ preferedProductSearchView: view })
    }

    const toggleFilter = () => {
      showFilter.value = !showFilter.value
      if (
        showFilter.value &&
        ownUser.value.setting.preferedProductSearchView === PreferedProductSearchView.LIST &&
        productsListRef.value
      ) {
        productsListRef.value.unselectProduct()
      }
    }

    const removeFilter = (filterName: string, value: FilterValue) => {
      useProductFilter().removeItemFromFilterSelection(filterName, value)
      updateUrl()
    }

    const removePriceRangeFilter = () => {
      useProductFilter().updatePriceRangeFilter({ start: undefined, end: undefined })
      updateUrl()
    }

    const removeStockCountFilter = () => {
      useProductFilter().updateStockCountFilter(undefined)
      updateUrl()
    }

    const updateUrl = () => {
      const filterTerm = useProductFilter().getFilterTerm().join(',')
      router.push({
        path: router.currentRoute.value.fullPath,
        query: {
          ...router.currentRoute.value.query,
          filter: filterTerm ? filterTerm : undefined,
          sort: useProductFilter().selection.sort
        }
      })
      searchProducts()
    }

    const selectCategory = (categorySearchTerm: string) => {
      useProductFilter().resetFilter(ProductSort.POPULARITY)
      useProductFilter().category = categorySearchTerm
      if (currentRoute.params.id !== categorySearchTerm) {
        router.push({
          name: 'Category',
          params: { id: categorySearchTerm }
        })
      }
    }

    const setKeyword = (keyword: string) => {
      trackEvent(ProductSearchEvent.CategorySearch)
      router.push({
        name: 'Category',
        params: {
          ...currentRoute.params
        },
        query: {
          ...currentRoute.query,
          keyword: keyword
        }
      })
    }

    const resetSearch = () => {
      router.push({
        name: 'Category',
        params: {
          ...currentRoute.params
        },
        query: {
          ...currentRoute.query,
          keyword: undefined
        }
      })
    }

    return {
      'products-list': productsListRef,
      addToCartEvent:
        props.mode === 'CATEGORY' ? CategoryEvent.AddToCart : ProductSearchEvent.AddToCart.Card,
      categoriesLoading,
      changeView,
      getActiveFilters,
      getActiveFilterLength,
      getPriceRangeFilter,
      getStockCountFilter,
      getPriceRangeFilterString,
      removeFilter,
      removePriceRangeFilter,
      removeStockCountFilter,
      getProductViews,
      items,
      isCategoryMode,
      mdAndDown,
      realtimeLoading,
      mobile,
      keyword,
      openCategories,
      ownUser,
      portalSettings,
      PreferedProductSearchView,
      productsLoading,
      resetSearch,
      selectCategory,
      selectedCategory,
      setKeyword,
      showCategories,
      showFilter,
      toggleFilter,
      toggleViewEvent: ProductSearchEvent.ToggleView,
      trackFilter:
        props.mode === 'CATEGORY'
          ? FilterEvent.GetFor('categories')
          : FilterEvent.GetFor('productSearch'),
      trackSort: props.mode === 'CATEGORY' ? CategoryEvent.Sort : ProductSearchEvent.Sort
    }
  }
})
</script>
