<template>
  <v-hover>
    <template #default="{ isHovering, props }">
      <v-card
        v-bind="props"
        :to="{ name: 'Product', params: { id: product.productId } }"
        class="product-card d-flex flex-column"
        height="100%"
      >
        <v-img
          class="flex-grow-0"
          aspect-ratio="1"
          :lazy-src="emptyPlaceholderImage"
          :src="product.imageUrl ? fitImage(product.imageUrl, 520, 520) : placeholderImage"
          :srcset="product.imageUrl ? getSrcset(product.imageUrl, 520, 520) : placeholderImage"
        >
          <sf-condition-chip
            v-if="product.condition && product.condition !== Condition.NEW"
            :condition="product.condition"
            style="background-color: white !important; position: absolute; bottom: 16px; left: 16px"
          />
        </v-img>
        <cart-button
          v-if="!$vuetify.display.smAndDown && !isRequest"
          :class="[{ 'd-block': isHovering }, 'card-button-position d-none mr-2 mt-2']"
          is-icon
          :product-id="product.productId"
          :quantity="1"
          :supplier-row-id="supplierRowId"
          :on-click-event="addToCartEvent"
        />
        <v-card-text class="d-flex flex-column">
          <sf-text ref="title" :class="{ 'two-lines': small }" class="text-justify mb-1">
            {{ product.title }}
          </sf-text>
          <product-identifiers
            v-if="showProductIdentifiers"
            class="mb-1"
            :article-number="product.articleNumber"
            :product-id="product.productId"
          />
          <v-spacer />

          <topi-cart-rental-summary-label
            :loading="loadingRealtimePrice"
            :price="product.price"
            :product-id="product.productId"
          />
          <div class="d-flex flex-row align-center mt-1">
            <v-icon
              v-if="product.stock"
              class="mr-2"
              size="medium"
              :color="getStatusColor(product.stock.status)"
              :icon="mdiCircle"
            />
            <v-spacer />
            <div class="d-flex flex-column">
              <div
                v-if="
                  product.price && (product.price.subscriptionModel || product.price.packagingModel)
                "
                ref="price-addition"
                class="d-flex justify-end text-primary"
              >
                <billing-period
                  v-if="product.price.subscriptionModel"
                  class="text-body-1"
                  :subscription-model="product.price.subscriptionModel"
                />
                <packaging-info
                  v-if="product.price.packagingModel"
                  class="text-body-1"
                  :packaging-model="product.price.packagingModel"
                />
              </div>
              <product-price
                v-if="product.price"
                :price="product.price.resellPrice"
                :product-id="product.productId"
                :show-loading-indicator="showRealtimeLoadingIndicator"
                :loading-realtime-prices="loadingRealtimePrice"
              />
            </div>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </v-hover>
</template>

<script setup lang="ts">
import BillingPeriod from '@/components/BillingPeriod.vue'
import PackagingInfo from '@/components/PackagingInfo.vue'
import CartButton from '@/components/button/CartButton.vue'
import SfConditionChip from '@/components/chips/SfConditionChip.vue'
import SfText from '@/components/text/SfText.vue'
import { Condition, MediumProduct, ShortProduct } from '@/generatedTypes'
import { emptyPlaceholderImage, fitImage, getSrcset, placeholderImage } from '@/helpers'
import ProductIdentifiers from '@/modules/product/components/ProductIdentifiers.vue'
import ProductPrice from '@/modules/product/components/ProductPrice.vue'
import { getStatusColor } from '@/modules/product/helpers'
import TopiCartRentalSummaryLabel from '@/modules/topi/components/TopiCartRentalSummaryLabel.vue'
import AddToCartButtonEvents from '@/modules/tracking/events/helper/addToCartButton'
import { mdiCircle } from '@mdi/js'
import { PropType, computed } from 'vue'

const componentProps = defineProps({
  addToCartEvent: {
    type: Object as PropType<AddToCartButtonEvents>,
    default: undefined
  },
  product: {
    type: Object as PropType<MediumProduct | ShortProduct>,
    required: true
  },
  showProductIdentifiers: {
    type: Boolean,
    default: false
  },
  loadingRealtimePrices: {
    type: Array as PropType<number[]>,
    default: () => []
  },
  small: {
    type: Boolean,
    default: false
  },
  showRealtimeLoadingIndicator: {
    type: Boolean,
    default: false
  }
})

const supplierRowId = computed(() => {
  return componentProps.product.price?.supplierRowId || undefined
})

const isRequest = computed(() => {
  return componentProps.product.price && componentProps.product.price.resellPrice.value > 0
    ? false
    : true
})

const loadingRealtimePrice = computed(() => {
  return componentProps.loadingRealtimePrices.includes(componentProps.product.productId)
})
</script>
