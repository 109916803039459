<template>
  <!-- Loading -->
  <list-loading v-if="loading" />

  <!-- Products -->
  <v-row v-else-if="products.length > 0" dense>
    <!-- List -->
    <v-col :lg="selectedProduct ? 7 : 12" :xl="selectedProduct ? 8 : 12">
      <list-content>
        <v-col cols="12">
          <template v-if="portalSettings.showSuppliers">
            <product-row
              v-for="(product, index) in products"
              :key="index"
              v-intersect.once="index === products.length - PAGE_SIZE / 2 ? loadNextPage : ''"
              :product="product"
              :add-to-cart-event="ProductSearchEvent.AddToCart.List"
              :loading-realtime-prices="loadingRealtimePrices.includes(product.productId)"
              :is-selected="selectedProduct && selectedProduct.productId == product.productId"
              :href="getProductLink(product.productId)"
              @click.exact.prevent="selectProduct(product)"
            />
          </template>
          <template v-else>
            <product-row
              v-for="(product, index) in products"
              :key="index"
              v-intersect.once="index === products.length - PAGE_SIZE / 2 ? loadNextPage : ''"
              :product="product"
              show-addd-to-cart
              :add-to-cart-event="ProductSearchEvent.AddToCart.List"
              :loading-realtime-prices="loadingRealtimePrices.includes(product.productId)"
              :to="getProductLink(product.productId)"
              @click="trackGoToProductPage(product.productId)"
            />
          </template>
        </v-col>
        <v-col v-if="loadingNextPage" cols="12" class="d-flex justify-center">
          <v-progress-circular indeterminate :size="50" color="primary" class="ma-1" />
        </v-col>
      </list-content>
    </v-col>

    <v-col v-if="selectedProduct" lg="5" xl="4">
      <products-list-detail
        :portal-settings="portalSettings"
        :product="selectedProduct"
        :loading-realtime-prices="loadingRealtimePrices.includes(selectedProduct.productId)"
      />
    </v-col>
  </v-row>

  <!-- Empty Message -->
  <list-empty
    v-else-if="products.length === 0"
    :icon="mdiStore"
    :message="keywordTooShortError ? $t('error.keywordTooShort') : $t('noProductsFound')"
  />
</template>

<script setup lang="ts">
import ListContent from '@/components/list/ListContent.vue'
import ListEmpty from '@/components/list/ListEmpty.vue'
import ListLoading from '@/components/list/ListLoading.vue'
import { MediumProduct } from '@/generatedTypes'
import { PortalSettings } from '@/modules/portalSettings/types'
import ProductRow from '@/modules/product/components/ProductRow.vue'
import ProductsListDetail from '@/modules/product/components/ProductsListDetail.vue'
import { getProductLink } from '@/modules/product/helpers'
import useProductSearch from '@/modules/product/useProductSearch'
import useRealtimePrices from '@/modules/product/useRealtimePrices'
import ProductSearchEvent from '@/modules/tracking/events/search'
import { trackEvent } from '@/modules/tracking/useTracking'
import { PAGE_SIZE } from '@/types'
import { mdiStore } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { PropType, ref, watch } from 'vue'

const props = defineProps({
  products: {
    type: Array as PropType<MediumProduct[]>,
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  portalSettings: {
    type: Object as PropType<PortalSettings>,
    required: true
  }
})

const emit = defineEmits(['select'])

const selectedProduct = ref<MediumProduct>()
const { loadingNextPage, keywordTooShortError } = storeToRefs(useProductSearch())
const { loading: loadingRealtimePrices } = storeToRefs(useRealtimePrices())

const selectProduct = (product: MediumProduct): void => {
  if (!selectedProduct.value || selectedProduct.value.productId !== product.productId) {
    selectedProduct.value = product
    useRealtimePrices().loadRealtimePricesForSearch({
      productId: product.productId,
      scale: 1
    })
    emit('select')
  }
}

const unselectProduct = (): void => {
  selectedProduct.value = undefined
}

const loadNextPage = (_entries: unknown, _observer: unknown, isIntersecting: boolean): void => {
  if (isIntersecting) {
    useProductSearch().loadNextPage()
  }
}

const trackGoToProductPage = (productId: number) => {
  trackEvent(ProductSearchEvent.GoToProductPage.List.withProduct(productId))
}

watch(
  () => props.products,
  () => {
    if (props.products.length <= PAGE_SIZE) {
      unselectProduct()
    }
  }
)
</script>
